<template>
  <!-- 客户信息 -->
  <div class="agentDetail_information">
    <!-- 板块中心区域 -->
    <div class="agentInfor_mid">
      <div class="agentInfor_header">
        <!-- 标题 -->
        <div class="agent_title">
          <p>编号:{{ titleData.cardid }}</p>
          <div class="gobackImg">
            <img src="../../../assets/image/user_detail/group_140.png" alt="" @click="goback">
          </div>
        </div>
        <div class="agentDetail_content">
          <p>营业执照号<span>{{ titleData.cardid }}</span></p>
          <p>公司名称<span>{{ titleData.gname }}</span></p>
          <p>代理负责人<span>{{ titleData.guser }}</span></p>
          <p>联系电话<span>{{ titleData.gphone }}</span></p>
          <!-- <p>代理合约
            <el-link type="primary" style="margin:0 0 3px
             15px"  @click="chakan">查看合约</el-link>
          </p> -->
          <p>代理级别
            <span v-if="titleData.dlevel == 1">总代理</span>
            <span v-else-if="titleData.dlevel == 2">省代理</span>
            <span v-else-if="titleData.dlevel == 3">市代理</span>
            <span v-else>区代理</span>
          </p>
          <p>所属地区<span>{{ titleData.dprov }}{{ titleData.city }}</span></p>
          <p class="mapFont">详细地址<span>{{ titleData.addr }}</span></p>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="agentInform_form">
        <!-- 基本信息 -->
        <div class="form_header">
          <h4>合约详情</h4>
          <div class="agent_btn">
            <p class="agent_font">
              <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="18px"
                style="margin-right:5px">
              <span>基本信息</span>
            </p>
            <div>
              <el-button class="change" style="background-color: #fff;color: #171717;width:78px;height:32px"
                @click="change">编辑</el-button>
              <el-button class="renew"
                style="background-color: #fff;color: #171717;margin-right:28px;width:78px;height:32px;"
                @click="renew">保存</el-button>
            </div>
          </div>
        </div>
        <!-- 版心区域 -->
        <div class="agentInform_center">
          <!-- 版心区域--表单 -->
          <div class="center_form">
            <el-form :model="inform" label-width="100px" class="demo-ruleForm">
              <!-- 营业执照号 -->
              <el-form-item label="营业执照号">
                <el-input v-model="inform.cardid" :disabled="disable" style="width:100%"></el-input>
              </el-form-item>
              <!-- 公司名称 -->
              <el-form-item label="公司名称">
                <el-input v-model="inform.gname" :disabled="disable" style="width:100%"></el-input>
              </el-form-item>
              <!-- 代理合约 -->
              <!-- <el-form-item label="代理合约">
                <el-input v-model="inform.agencyContract" :disabled="true" style="width:100%"></el-input>
              </el-form-item> -->
              <!-- 代理负责人 -->
              <el-form-item label="代理负责人">
                <el-input v-model="inform.guser" style="width:100%" :disabled="disable"></el-input>
              </el-form-item>
              <!-- 联系电话 -->
              <el-form-item label="联系电话">
                <el-input v-model="inform.gphone" style="width:100%" :disabled="disable"></el-input>
              </el-form-item>
              <!-- 代理级别 -->
              <el-form-item label="代理级别">
                <el-select v-model="inform.dlevel" placeholder="请选择" style="width:100%;" :disabled="disable">
                  <el-option v-for="item in levels" :key="item.id" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 代理开始时间 -->
              <el-form-item label="代理开始">
                <el-date-picker v-model="inform.atime" type="datetime" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss" style="width:100%;margin-left:10px" :disabled="disable">
                </el-date-picker>
              </el-form-item>
              <!-- 代理结束时间 -->
              <el-form-item label="代理结束" class="times">
                <el-date-picker v-model="inform.stime" type="datetime" placeholder="选择日期"
                  style="width:100%;margin:0 8px 0 0" value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="disable"></el-date-picker>
                <!-- 所在区域 -->
              </el-form-item>
              <el-form-item label="所在区域">
                <el-cascader size="large" :options="options" v-model="areaArr" @change="handleChange"
                  style="width:100%;" :disabled="disable">
                </el-cascader>
              </el-form-item>
              <!-- 代理地址 -->
              <el-form-item label="代理地址" class="detailAddress">
                <el-cascader size="large" :options="options" v-model="detailArr" @change="handleChangeDetail"
                  style="width:100%;margin-left:10px" :disabled="disable">
                </el-cascader>
              </el-form-item>
              <!-- 地图选择 -->
              <el-form-item class="detailAdrs" label="地图选择">
                <el-input v-model="inform.addr" placeholder="请选择您的详细地址" style="width:100%;"
                  suffix-icon="el-icon-map-location" :disabled="disable"></el-input>
                <div class="map_div" @click="openMap"></div>
                <el-dialog title="" :visible.sync="isMap.isshow" width="30%" :close-on-click-modal="false">
                  <!-- 引入地图组件和输入框组件 -->
                  <div id="search_ipt">
                    <searchs></searchs>
                  </div>
                  <maps :isMap="isMap"></maps>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="isMap.isshow = false">取 消</el-button>
                    <el-button type="primary" @click="isMap.isshow = false">确 定</el-button>
                  </span>
                </el-dialog>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 合同信息 -->
        <div class="constractInform">
          <!-- 合约信息头部 -->
          <div class="header_inform">
            <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="18px"
              style="margin-right:5px">
            <h4>合同信息</h4>
          </div>
          <div class="constract_content">
            <el-form :model="inform" label-width="80px">
              <!-- 生效日期 -->
              <el-form-item label="生效日期">
                <el-date-picker v-model="inform.qtime" type="datetime" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss" :disabled="disable"></el-date-picker>
              </el-form-item>
              <br>
              <!-- 结束日期 -->
              <el-form-item label="结束日期">
                <el-date-picker v-model="inform.qtime" type="datetime" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss" :disabled="disable"></el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <!-- 合同和证件展示 -->
          <div class="documents_show">
            <el-form :model="inform" label-width="80px">
              <!-- 合同文件 -->
              <el-form-item label="合同文件">
                <el-upload class="avatar-uploader" :show-file-list="true" :file-list="fileList"
                  :on-change="documentUpload" :action="$baseUrl + '/api/upload/annex/tract/up'"
                  :auto-upload='false' multiple :limit="3" :disabled="disable" :on-preview="clickFile"
                  :on-exceed="handleExceed">
                  <el-button plain icon="el-icon-upload2"
                    style="font-size:16px;color:#0666F5;border:none">文件上传</el-button>
                  <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png/pdf文件</div>
                </el-upload>
              </el-form-item>
              <!-- 证件信息 -->
              <el-form-item label="证件信息">
                <div class="doucments">
                  <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="zhengjianUp"
                    v-loading="loadingzhengjian"
                    :action="$baseUrl + '/api/upload/annex/tract/up'" :limit="1" :auto-upload='false'
                    :disabled="disable">
                    <div class="documents">
                      <img v-if="this.inform.tract[0]" :src="file1Url" alt="" style="width:117px;height:66px;"
                        @click="showImg(file1Url)">
                      <i v-else class="el-icon-circle-plus" style="color:#0666F5"></i>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
              <!-- 资质证明 -->
              <el-form-item label="资质证明">
                <div class="doucments">
                  <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="zizhiUp"
                    v-loading="loadingzizhi"
                    :action="$baseUrl + '/api/upload/annex/tract/up'" :limit="1" :auto-upload='false'
                    :disabled="disable">
                    <div class="documents">
                      <img v-if="this.inform.tract[1]" :src="zizhiUrl" alt="" style="width:117px;height:66px;"
                        @click="showImg(zizhiUrl)">
                      
                      <i v-else class="el-icon-circle-plus" style="color:#0666F5"></i>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 授权信息 -->
        <div class="authorized_inform">
          <div class="authorized_header">
            <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" style="margin-right:5px">
            <h4>授权信息</h4>
          </div>
          <div class="authorized_content">
            <el-form :model="inform" label-width="80px">
              <!-- 邮箱 -->
              <el-form-item label="邮箱">
                <el-input v-model="inform.email" :disabled="disable"></el-input>
              </el-form-item>
              <!-- 销售员 -->
              <el-form-item label="销售员" class="leader">
                <el-input v-model="inform.saler" style="width:90%" :disabled="disable"></el-input>
              </el-form-item>
              <!-- 销售员电话 -->
              <el-form-item label="销售员电话" class="leader">
                <el-input v-model="inform.salephone" style="width:90%" :disabled="disable"></el-input>
              </el-form-item>
              <!-- 备注信息 -->
              <div></div>
              <el-form-item label="备注信息" class="remark">
                <el-input type="textarea" placeholder="请输入内容" v-model="inform.remark" maxlength="200" show-word-limit
                  :disabled="disable">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <agreements :obj="obj" :heyue="heyue"></agreements>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { provinceAndCityDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
import { msg } from "../../../common/public";
// 引入地图组件和输入框组件
import maps from "../../customerInformation/components/map";
import searchs from "../../customerInformation/components/search";
import { addrSwitch, fileuploads, uploadImg, checkInpput } from "@/assets/js/public";
import { fileUpload, agentUp } from "@/utils/api";
// 引入查看合约的弹窗
import agreements from "./agreement2.vue";
export default {
  components: {
    maps,
    searchs,
    agreements
  },
  data() {
    return {
      obj: {
        isShow: false
      },
      isMap: {//地图的dialog的显示
        isshow: false
      },
      fileList: [],
      inform: {},//代理商信息
      btnType: '',
      areaArr: [],//所在区域
      detailArr: [],//代理地址
      options: provinceAndCityDataPlus,//省市地址信息
      file1Url: '',//证件信息
      zizhiUrl: '',//资质证明
      heyue: [],
      disable: true,
      dialogImageUrl: '',
      dialogVisible: false,
      loadingzhengjian: false,
      loadingzizhi: false,
      titleData: {}
    };
  },
  computed: {
    ...mapState('agentDetail', ['agentTypes', 'levels'])
  },
  mounted() {
    let informs = JSON.parse(sessionStorage.getItem('informs'));
    this.inform = informs;
    this.inform.annex = JSON.parse(this.inform.annex);
    this.fileList = this.inform.annex.map(e => ({ name: e.name, url: this.$URL + e.url }))
    for(let k in this.inform) {
      this.titleData[k] = this.inform[k]
    }
    this.areaArr = addrSwitch(this.inform.prov, this.inform.city)
    this.detailArr = addrSwitch(this.inform.dprov, this.inform.dcity);
    this.inform.tract = JSON.parse(this.inform.tract)
    this.file1Url = this.$URL + this.inform.tract[0]
    this.zizhiUrl = this.$URL + this.inform.tract[1]
  },

  methods: {
    showImg(img) {
      if (!this.disable) return
      this.dialogVisible = true;
      this.dialogImageUrl = img;
    },
    // 查看文件
    clickFile(file) {
      window.open(file.url, false)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    goback() {
      this.$router.go(-1)
    },
    // 所在区域
    handleChange() {
      let location = "";
      if (this.areaArr[0] == '') { //说明第一个地址选择了全部,需要提示他选择地址
        msg('请选择你所在省', 'error')
        return false
      };
      if (this.areaArr[1] == '') { //在选择第二项地址时选择了全部，未详细 提醒
        msg('请选择你所在市', 'error')
        return false
      }
      // 将地区码转话为地址
      for (let i = 0; i < this.areaArr.length; i++) {
        location += CodeToText[this.areaArr[i]];
      }
    },
    handleChangeDetail() {
      let location = '';//获取选择的地址
      if (this.detailArr[0] == '') { //说明第一项选择了全部  提醒选择详细地址
        msg('请选择你所在省', 'error')
        return false
      }
      if (this.detailArr[1] == '') {
        msg('请选择你所在市的详细地址', 'error')
        return false
      }
      // 将地区码转话为地址
      for (let i = 0; i < this.detailArr.length; i++) {
        location += CodeToText[this.detailArr[i]];
      }
    },
    // 编辑
    change() {
      // 获取按钮实例
      let changeBtn = document.querySelector('.change');
      let renewBtn = document.querySelector('.renew');
      // 按钮变色
      changeBtn.style.backgroundColor = '#0666F5';
      changeBtn.style.color = '#fff';
      renewBtn.style.backgroundColor = '#fff';
      renewBtn.style.color = '#171717';
      this.disable = false
    },
    // 保存
    async renew() {
      // 获取按钮实例
      let changeBtn = document.querySelector('.change');
      let renewBtn = document.querySelector('.renew');
      // 表单验证
      if (!checkInpput(this.inform)) return
      const res = await agentUp({...this.inform, annex: JSON.stringify(this.inform.annex), tract: JSON.stringify(this.inform.tract)})
      if (res.code === 1) {
        msg('保存成功', 'success')
        // 按钮变色
        renewBtn.style.backgroundColor = '#0666F5';
        renewBtn.style.color = '#fff';
        changeBtn.style.backgroundColor = '#fff';
        changeBtn.style.color = '#171717';
        this.disable = true
      }
    },
    // 打开地图
    openMap() {
      this.isMap.isshow = true
    },
    //资料变更 -- 合同文件上传
    documentUpload(file) {
      let fileObj = fileuploads(file);
      let { name } = fileObj
      fileUpload({ file: fileObj, uid: 2, id: 20, type: 1 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.annex.push({name,url})
          this.heyue.push(this.$URL + url);
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    // 资料变更 -- 证件的文件上传
    zhengjianUp(file) {
      let fileObj = uploadImg(file);
      this.loadingzhengjian = true
      fileUpload({ file: fileObj, uid: 2, id: 20, type: 1 }, true).then(res => {
        if (res.code == 1) {
          this.file1Url = this.$URL + res.data.data
          if (this.inform.tract.length > 0) {
            this.inform.tract[0] = res.data.data
          } else if (this.inform.tract.length === 0) {
            this.inform.tract.push(res.data.data)
          }
          this.loadingzhengjian = false
        }
      }

      ).catch(err => {
        return console.log(err)
      })
    },
    //资料变更 -- 资质证明
    zizhiUp(file) {
      let fileObj = uploadImg(file);
      this.loadingzizhi = true
      fileUpload({ file: fileObj, uid: 2, id: 20, type: 1 }, true).then(res => {
        if (res.code == 1) {
          this.zizhiUrl = this.$URL + res.data.data
          if (this.inform.tract.length === 2) {
            this.inform.tract[1] = res.data.data
          } else if (this.inform.tract.length < 2) {
            this.inform.tract.push(res.data.data)
          }
          this.loadingzizhi = false
        }
      }

      ).catch(err => {
        return console.log(err)
      })
    },

    // 
  },
};
</script>

<style scoped>
/*滚动条样式*/
.agentInform_form::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.agentInform_form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.agentInform_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.agentDetail_information {
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}

.agentDetail_information .agentInfor_mid {
  width: 83.8vw;
  height: 87vh;
}

/* 头部--开始 */
.agentInfor_mid .agentInfor_header {
  width: 100%;
  height: 176px;
  background-color: #fff;
}

.agent_title {
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 1.5% 0 1.5%;
}

.agent_title>p {
  font-family: "SHSCN_Bold";
  font-size: 18px;
  margin-left: 1px;
}

.agentDetail_content {
  height: calc(100% - 35%);
  padding: 0 0.5% 0 1.5%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
}

.agentDetail_content p {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  color: #ababab;
}

.agentDetail_content span {
  color: #171717;
  margin: 0 0 0 4%;
}

/* 头部--结束 */

/* 表格区域--开始 */
/* 表单所有输入框的底部边距 */
.el-form {
  margin-top: 26px;
}

.el-form /deep/ .el-form-item {
  margin-bottom: 26px !important;
}

.agentInfor_mid .agentInform_form {
  width: 100%;
  height: 71.8vh;
  background-color: #fff;
  margin-top: 7px;
  /* overflow: hidden; */
  overflow-y: scroll;
}

.form_header h4 {
  font-size: 18px;
  font-family: "SHSCN_Bold";
  margin: 10px 0 0 28px;
}

.agentInform_form .agent_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.agentInform_form .agent_btn .el-button {
  height: 3.43vh;
  line-height: 3.43vh;
  padding: 0 10px;
}

/* 基本信息 */
.agent_font {
  display: flex;
  align-items: center;
  margin: 0 0 0 36px;
  font-family: "SHSCN_Regular";
  color: #171717;
}

.mapFont {
  width: 180%;
}

/* 版心区域 */
.agentInform_form .agentInform_center {
  width: 95%;
  margin: 0 0 0 26px;
  /* border: 1px solid #333; */
}

/* 版心区域--表单 */
.agentInform_center .center_form .el-form {
  /* width: 100%; */
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  font-family: "SHSCN_Regular";
  /* justify-items: center; */
  justify-content: space-between;
}

/* 生效时间 */
.times /deep/ .el-form-item__content {
  display: flex;
}

/* 详细地址 */
.detailAdrs /deep/ .el-input__icon {
  color: #0666f5;
}

/* 地图选择 */
.map_div {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
}

/* 搜索框 */
#search_ipt {
  position: absolute;
  z-index: 999;
  /* border: 1px solid #333; */
  position: absolute;
  top: 110px;
  left: 38px;
}

.detailAdrs /deep/ .el-dialog {
  width: 50% !important;
  margin-top: 5vh !important;
}

/* 合同信息 */
.header_inform {
  margin: 0px 0 0 36px;
  display: flex;
}

.header_inform h4 {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}

.constract_content {
  /* margin:1% 0 0 0 ; */
  font-family: "SHSCN_Regular";
}

.constract_content /deep/ .el-form {
  display: grid;
  grid-template-columns: 20% 20% 20%;
}

/* 合同证件信息展示 */
.documents_show .el-form {
  font-family: "SHSCN_Regular";
  margin-top: 0px;
}

.documents_show .el-form .doucments {
  width: 117px;
  height: 66px;
  background-color: #f1f3f8;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doucments i {
  font-size: 18px;
}

.constract_content,
.documents_show {
  margin-left: 47px;
}

/* 续约模块 */
.doc /deep/ .el-form-item__content {
  display: flex;
}

.doc .doucments {
  margin-right: 10px;
}

/* 授权信息 */
.authorized_header {
  display: flex;
  align-items: center;
  margin: 40px 0 0 36px;
}

.authorized_header img {
  width: 3px;
  height: 18px;
}

.authorized_header h4 {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}

/* 授权信息--表单区域 */
.authorized_content {
  margin: 26px 0 0 47px;
}

.authorized_content /deep/ .el-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 20% 30%;
  margin-top: 1vh;
  font-family: "SHSCN_Regular";
}

.remark {
  margin-top: 26px;
}

.remark /deep/ .el-textarea__inner {
  width: 235%;
  height: 68px !important;
  resize: none;
}

.remark /deep/ .el-input__count {
  height: 16px;
  bottom: 18px !important;
  right: -290px !important;
}

/* 连邦负责人 */
.leader /deep/ .el-form-item__label {
  padding: 0 0;
}

.leader /deep/ .el-form-item__content {
  margin-left: 100px !important;
}

/* 多媒体查询 */
@media screen and (min-width: 1920px) {

  /* 头部字体的样式 */
  .agentDetail_content span {
    font-size: 16px;
    display: inline;
  }

  .times /deep/ .el-form-item__content {
    margin-left: 27% !important;
  }

  /* 备注信息 */
  .remark /deep/ .el-input__count {
    height: 16px;
    bottom: 18px !important;
    right: -130% !important;
  }
}

/* 屏幕大于 1600px 或小于 1920px 时应用该样式 */
@media screen and (min-width: 1600px) and (max-width: 1920px) {

  /* 头部字体的样式 */
  .agentDetail_content span {
    font-size: 14px;
    display: inline;
  }
}

/* 分辨率 最小800 最大1600 */
@media screen and (min-width: 800px) and (max-width: 1600px) {

  /* 头部字体的样式 */
  .agentDetail_content span {
    transform: scale(0.6);
    display: inline;
  }
}

/* 表格区域--结束 */
</style>