import { TextToCode } from "element-china-area-data";
import { Message } from "element-ui";

// 地址截取
export function getaddr(addr) {
   let pIndex = addr.search('省');//省的下标
  //  没有省 
   if(pIndex == -1){
    let cIndex = addr.search('市');//市的下标
    let city = addr.slice(pIndex+1,cIndex+1);
    let dIndex = addr.search('区');
    //第一个区  例如北京市-市辖区-朝阳区
    let district = addr.slice(cIndex+1,dIndex+1);//县\区
    return [city,district]
   }else{ //有省
    let prov = addr.slice(0,pIndex+1)
    let cIndex = addr.search('市');//市的下标
    let city = addr.slice(pIndex+1,cIndex+1);
    return [prov,city]
   }
}

 // 地址转为坐标 arr--要转code的数组 addrCon--盛放code的容器
 export function textToCode(arr,addrCon){
  if(arr[0] != '' && arr[1] != '' && arr.length == 2){
    // console.log(TextToCode[arr[0]])
    let firCode = TextToCode[arr[0]].code;
    addrCon.push(firCode)
    for(let key in TextToCode[arr[0]]){
      if(key == arr[1]){
       let seconCode = TextToCode[arr[0]][key].code
       addrCon.push(seconCode)
      }
    }
    return addrCon
  }else{
      return addrCon
  }
 }

//  表单验证1
export function checkInpput(form){
  // 身份证号或者社会信用代码
  let cardidReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  let creditReg = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
  let httpReg = /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/ig
  //手机号验证
  let phoneReg = /^1[3|5|7|8|9]\d{9}$/;
  let emailReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
  if(!form.cardid){
    Message.error('社会信用代码或者身份证信息不能为空');
    return false
  }else if(!(cardidReg.test(form.cardid)) && !(creditReg.test(form.cardid))){
    Message.error('社会信用代码格式不正确或者身份证号不正确');
    return false 
  }else if(!form.gname){
    Message.error('企业名称不能为空');
    return false 
  }else if(!form.gtype){
    Message.error('请选择企业性质');
    return false 
  }else if(!httpReg.test(form.gurl)){
    Message.error('请检查公司主题链接是否正确');
    return false 
  }else if(!form.gurl){
    Message.error('请填写公司主题链接');
    return false 
  }else if(!form.guser){
    Message.error('项目负责人不能为空');
    return false
  }else if(!form.gphone){
    Message.error('项目负责人联系方式不能为空');
    return false
  }else if(!(phoneReg.test(form.gphone))){
    Message.error('联系方式格式不正确');
    return false
  }else if(!form.email){
    Message.error('邮箱不能为空');
    return false
  }else if(!(emailReg.test(form.email))){
    Message.error('邮箱格式不正确');
    return false
  }else if(!form.prov){
    Message.error('请选择您所在的省/市');
    return false
  }else if(!form.city){
    Message.error('请选择您所在的市');
    return false
  }else if(!form.dprov){
    Message.error('请选择您所代理的省/市');
    return false
  }else if(!form.dcity){
    Message.error('请选择您所代理的市/区');
    return false
  }else if(!form.dlevel){
    Message.error('请选择代理商级别');
    return false
  }else if(!form.qtime){
    Message.error('请选择合同签订的日期');
    return false
  }else if(!form.dtime){
    Message.error('请选择到期时间');
    return false
  }else if(!form.atime){
    Message.error('请选择开始代理日期');
    return false
  }else if(!form.annex){
    Message.error('请上传合同附件/图片');
    return false
  }else if(!form.tract){
    Message.error('请上传证件照片');
    return false
  } else if(!form.stime){
    Message.error('请选择结束代理日期');
    return false
  }else if(!form.saler){
    Message.error('请填写销售员名称');
    return false
  }else if(!form.salephone){
    Message.error('请填写销售员联系方式');
    return false
  }else if(!form.gjuser){
    Message.error('请填写紧急联系人名称');
    return false
  }else if(!form.gjphone){
    Message.error('请填写紧急联系人联系方式');
    return false
  }else {
    return true;
  }
}

//  表单验证2
export function checkInpput2(form){
  // 身份证号或者社会信用代码
  let cardidReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  let creditReg = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
  let httpReg = /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/ig
  //手机号验证
  let phoneReg = /^1[3|5|7|8|9]\d{9}$/;
  let emailReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
  if(!form.cardid){
    Message.error('社会信用代码或者身份证信息不能为空');
    return false
  }else if(!(cardidReg.test(form.cardid)) && !(creditReg.test(form.cardid))){
    Message.error('社会信用代码格式不正确或者身份证号不正确');
    return false 
  }else if(!form.gname){
    Message.error('企业名称不能为空');
    return false 
  }else if(!form.gtype){
    Message.error('请选择企业性质');
    return false 
  }else if(!httpReg.test(form.gurl)){
    Message.error('请检查公司主题链接是否正确');
    return false 
  }else if(!form.gurl){
    Message.error('请填写公司主题链接');
    return false 
  }else if(!form.email){
    Message.error('邮箱不能为空');
    return false
  }else if(!(emailReg.test(form.email))){
    Message.error('邮箱格式不正确');
    return false
  }else if(!form.prov){
    Message.error('请选择您所在的省/市');
    return false
  }else if(!form.city){
    Message.error('请选择您所在的市');
    return false
  }else if(!form.addr){
    Message.error('请补充完整详细地址');
    return false
  }else if(!form.annex){
    Message.error('请上传合同附件/图片');
    return false
  }else if(!form.tract){
    Message.error('请上传证件照片');
    return false
  }else if(!form.saler || !form.salephone){
    Message.error('请填写完整销售员信息');
    return false
  }else if(!form.gjuser || !form.gjphone){
    Message.error('请填写完整紧急联系人信息');
    return false
  }else if(!form.guser || !form.gphone){
    Message.error('请填写完整负责人信息');
    return false
  }else if(!(phoneReg.test(form.gphone)) || !(phoneReg.test(form.gjphone)) || !(phoneReg.test(form.salephone))){
    Message.error('手机号格式不正确,请检查');
    return false
  }else {
    return true;
  }
}

// 图片上传
export function uploadImg(file){
  if(!file){ //图片不存在
    return
  }else{ //存在
    let {size , type} = file.raw;
    if(size > 2* 1024 * 1024){
      Message.error('图片大小不能超过2M');
      return;
    }
    let typeArr = ["image/png", "image/jpg", "image/gif", "image/jpeg"]
    if(typeArr.indexOf(type) == -1){
      Message.error('只能上传.png、.gif、.jpg或.jpeg格式的图片')
      return
    }
    return file.raw
  }
}

// 合同上传
export function fileuploads(file){
  if(!file){ //图片不存在
    return
  }else{ //存在
    let {size , type} = file.raw;
    if(size > 10 * 1024 * 1024){
      Message.error('图片大小不能超过10M');
      return;
    }
    let typeArr = ["image/jpg","image/jpeg","image/png",'application/pdf']
    if(typeArr.indexOf(type) == -1){
      Message.error('只能上传.png、.jpg、.jpeg格式的图片或者.pdf格式的文件')
      return
    }
    return file.raw
  }
}
// 时间转换
export function changeTime(time){
  let year = time.getFullYear();
  let month = parseInt(time.getMonth())+1;
  let day = time.getDate();
  let hours = time.getHours() <= 9 ? '0' + time.getHours() : time.getHours();
  let minutes = time.getMinutes() <= 9 ? '0' + time.getMinutes() : time.getMinutes();
  let seconds = time.getSeconds() <= 9 ? '0' + time.getSeconds() : time.getSeconds();
  let datas = `${year}-${month <= 9 ? '0' + month : month}-${day <= 9 ? '0' + day : day} ${hours}:${minutes}:${seconds}`;
  return datas
}

// 地址转换
export function addrSwitch(addr1,addr2){
  let firstCode = TextToCode[addr1];
  let arr = []
  let secondCode = ''
  arr.push(firstCode.code);
  Object.keys(firstCode).map(item => {
    if(item == '全部'){
      return
    }else{
      for(let ind in firstCode){
        if(ind == addr2){
          secondCode = firstCode[ind].code
        }
      }
    }
  })
  arr.push(secondCode)
  return arr
}

// 产品信息的表单验证
export function inputCheck(form){
  let Reg = /(\d{0,9}\m\m\*)(\d{0,9}\m\m\*)(\d{0,9}\m\m)/;
  if(!form.name){
    Message.error('产品名称不能为空');
    return
  }else if(!form.chicun){
    Message.error('产品尺寸不能为空');
    return
  }else if(!(Reg.test(form.chicun))){
    Message.error('产品尺寸格式不正确');
    return
  }else if(!form.weight){
    Message.error('产品重量不能为空');
    return
  }else if(!form.model){
    Message.error('产品型号不能为空');
    return
  }else if(!form.visu){
    Message.error('额定电压不能为空');
    return
  }else if(!form.aisu){
    Message.error('额定电流不能为空');
    return
  }else if(!form.type){
    Message.error('请选择产品类别');
    return
  }else if(!form.info){
    Message.error('产品介绍不能为空');
    return
  }else if(!form.isou){
    Message.error('请选择产品功能');
    return
  }else if(!form.isou){
    Message.error('请选择产品功能');
    return
  }else if(!form.imgUrl){
    Message.error('请选择产品图片');
    return
  }else if(!form.status){
    Message.error('请选择产品状态');
    return
  }
}
